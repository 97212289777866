<template>
  <div id="admin-users">
    <div class="title">
      Kullanıcılar <span v-if="result.TotalCount != 0">({{ result.TotalCount }})</span>
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Kullanıcılar</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-tooltip class="item" effect="dark" content="Export" placement="top">
          <el-button class="export" @click="exportFile()"><i class="icon-export"></i></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Import" placement="top">
          <el-button class="import" @click="openImportExcelFile"><i class="icon-import"></i></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Örnek excel dokümanı" placement="top">
          <el-button class="export" @click="exportFileExample()"><i class="icon-help-menu"></i></el-button>
        </el-tooltip>
      </div>
    </div>

    <div class="search-status">
      <div class="status">
        <div class="show">Göster</div>
        <el-select class="first" v-model="filter.PageSize" placeholder="Seçiniz...">
          <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
        </el-select>

        <div class="second">
          <el-date-picker v-model="filter.DateRange" align="center" format="dd.MM.yyyy" type="daterange" range-separator="-" start-placeholder="..." end-placeholder="..." prefix-icon="icon-calendar"> </el-date-picker>
        </div>
        <el-select class="third" v-model="filter.ActiveStatus" placeholder="Durum" clearable @clear="filter.ActiveStatus = null">
          <el-option v-for="item in getEnums.find((x) => x.Title == 'ActiveStatus').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
        </el-select>
      </div>

      <div class="search">
        <el-input style="width: 290px" placeholder="Arama..." v-model="filter.SearchTerm" clearable>
          <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
        </el-input>
      </div>
    </div>
    <div class="all-data">
      <div class="list-table">
        <el-table ref="multipleTable" :data="UsersList" @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column sortable type="selection" align="center" style="text-center" width="50"> </el-table-column>
          <el-table-column sortable prop="userName" label="KULLANICI" width="300">
            <template slot-scope="scope">
              <div>
                <div class="name-all">
                  <div class="name-short" style="text-transform: uppercase" :class="[{ first: scope.$index % 4 == 0 }, { second: scope.$index % 4 == 1 }, { third: scope.$index % 4 == 2 }, { fourth: scope.$index % 4 == 3 }]">{{ scope.row.FirstName[0] }}{{ scope.row.LastName[0] }}</div>
                  <div class="name-container">
                    <div class="name" v-if="scope.row.FirstName && scope.row.LastName">{{ scope.row.FirstName.trim().split(" ").map(x=> x[0] + "***").reduce((x,y)=> x+" "+y, "") }} {{ scope.row.LastName.trim().split(" ").map(x=> x[0] + "***").reduce((x,y)=> x+" "+y, "") }}</div>
                    <div class="mail">{{ scope.row.PhoneNumber }}</div>
                    <div class="mail">{{ scope.row.Email }}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column sortable label="İL/İLÇE" stripe width="250">
            <template slot-scope="scope">
              <div class="" v-if="scope.row.Address != null">{{ scope.row.Address }}</div>
              <div class="" v-else>Belirtilmemiş</div>
            </template>
          </el-table-column>
          <el-table-column sortable label="GÖRÜŞME" width="280" stripe>
            <template slot-scope="scope">
              Toplam: {{ scope.row.AppointmentStatistics.TotalAppointment }}
              <br />
              Tamamlanan: {{ scope.row.AppointmentStatistics.CompletedAppointment }}
              <br />
              İptal: {{ scope.row.AppointmentStatistics.CancelledAppointment }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="date" label="ÜYELİK TARİHİ" stripe width="250">
            <template slot-scope="scope">{{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY HH:mm") }}</template>
          </el-table-column>
          <el-table-column sortable prop="SegmentId" label="Segment" stripe min-width="250">
            <template slot-scope="scope" >
             <div v-if="scope.row.SegmentId"> {{ scope.row.SegmentTitle }} </div> 
             <div v-else>Atanmamış</div>
          </template>
          </el-table-column>
          <el-table-column label="DURUM">
            <template slot-scope="scope">
              <div class="color-dot">
                <div class="dot" :class="{ green: scope.row.ActiveStatus == 1, red: scope.row.ActiveStatus == 2 }"></div>
                {{ getEnumsDisplay("ActiveStatus", scope.row.ActiveStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="threeDot" align="center" label="" stripe width="80">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="goDetails(scope.row)" style="display: block">Detayları Görüntüle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="scope.row.ActiveStatus != 2">
                    <span style="display: block" @click="userStatusChange(scope.row.ID)">Pasif Yap</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="scope.row.ActiveStatus == 2">
                    <span style="display: block" @click="userStatusChange(scope.row.ID)">Aktif Yap</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
              <div class="no-data-text">Herhangi bir kullanıcı kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>
    </div>
    <div class="bottom">
      <div class="pagination">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount : filter.PageSize * filter.Page }} {{ $localization('web-pagination-info-text') }} {{ result.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="result.TotalCount"></el-pagination>
      </div>
    </div>
    <el-dialog custom-class="import-excel-file" :close-on-click-modal="false" :visible.sync="importExcelDialogVisible" :destroy-on-close="true" :show-close="false">
      <importExcelFile :excelFile="excelFile" @close="closeImportExcelDialogVisible()"></importExcelFile>
    </el-dialog>
  </div>
</template>

<script>
import importExcelFile from "../admin/importExcelFile.vue";

export default {
  components: {
    importExcelFile,
  },
  name: "Home",
  data() {
    return {
      importExcelDialogVisible: false,
      excelFile: null,
      multipleSelection: [],
      pagesize: [10, 20, 30, 40],
      searchTimeout: null,
    };
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    closeImportExcelDialogVisible() {
      this.importExcelDialogVisible = false;
    },
    openImportExcelFile() {
      this.importExcelDialogVisible = true;
    },
    async userStatusChange(ID) {
      var payload = {
        Id: ID,
      };

      this.$confirm("Hesabı aktif/pasif yapmak istediğinizden emin misiniz?", "Uyarı", {
        confirmButtonText: "Evet",
        confirmButtonClass: "pop-confirm-class",
        cancelButtonText: "Hayır",
        cancelButtonClass: "pop-cancel-class",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/User/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
          }
          await this.getData();
        })

        .catch(() => {});
    },

    exportFile() {
      var label = "KullaniciListesi.xlsx";
      this.$client
        .get("/User/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    exportFileExample() {
      var label = "OrnekKullaniciListesi.xlsx";
      this.$client
        .get("/User/ExampleExport", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    handleSelectionChange(val) {
      this.$store.commit("setMultipleSelect", val);
    },
    goDetails(item) {
      this.$router.push({
        path: "/admin/kullanici-detay/" + item.ID,
      });
    },
    async getData() {
      await this.$store.dispatch("getUsersList", this.filter);
    },
  },
  computed: {
    filter() {
      return this.$store.getters.getUsersFilter;
    },
    UsersList() {
      return this.$store.getters.getUsersList;
    },
    result() {
      return this.$store.getters.getUsersResult;
    },

    getEnums() {
      return this.$store.state.auth.enums;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.filter.DateRange != null) {
          this.filter.CreatedDateMin = this.filter.DateRange[0];
          this.filter.CreatedDateMax = this.filter.DateRange[1];
        } else {
          this.filter.CreatedDateMin = null;
          this.filter.CreatedDateMax = null;
        }
        window.clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.getData();
        }, 400);
      },
      // "filter.ActiveStatus": function () {
      //   this.getData();
      // },
    },
  },
};
</script>

<style lang="less">
#admin-users {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;
        i {
          background: #44566c;
        }
      }
    }
  }

  .search-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 42px;
    margin-bottom: 22px;
    .show {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #44566c;
      margin-right: 10px;
    }
    .status {
      display: flex;
      align-items: center;
      gap: 20px;

      .first {
        width: 75px;
      }

      .second {
        .el-date-editor--daterange.el-input__inner {
          width: 225px;
          height: 40px;
          align-items: center;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;
          ::placeholder {
            color: #44566c;
            font-size: 15px;
            font-weight: 400;
          }
          i {
            line-height: unset;
          }
          .icon-calendar {
            padding: 0 15px;
            background-color: #44566c;
          }
        }
      }
      .third {
        width: 145px;
      }
    }
    .search {
      .el-input {
        width: 290px;
        height: 40px;
        input {
          padding-left: 40px;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;
        }
        ::placeholder {
          color: #8697a8;
          font-size: 15px;
          font-weight: 400;
        }
        i {
          color: #8697a8;
          font-size: 15px;
          background-color: rgba(255, 255, 255, 0);
          padding: 0 10px;
        }
      }
    }
  }
  .el-input__inner {
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
  }
  .el-input__inner::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #44566c;
  }
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }
  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;
        &::before {
          height: 0;
        }
      }
      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }
      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }
      table td {
        border: none;
      }
      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }
      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }
      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        .cell {
          font-weight: 500;
        }
      }
      tbody {
        height: auto;
        .el-table__row {
          filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
          &:hover {
            background-color: white !important;
          }
          td {
            height: 60px;
          }
          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
          background-color: white !important;
          border-radius: 10px !important;
        }
        .el-button {
          border: none;
        }
        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }
        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;
            i {
              height: 40px;
            }
          }
        }
        .three-dots {
          background-color: inherit;
          i {
            height: 25px;
            width: 25px;
          }
        }
        .cell {
          .name-all {
            display: flex;
            gap: 10px;
            align-items: center;
            .name-short {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 40px;
              min-height: 40px;
              border: none;
              border-radius: 50%;
              font-size: 14px;
              font-weight: 500;
            }
            .name-short.first {
              background-color: #ff3d57;
              color: white;
            }
            .name-short.second {
              background-color: #fdbf5e;
              color: white;
            }
            .name-short.third {
              background-color: #22cce2;
              color: white;
            }
            .name-short.fourth {
              background-color: #ff8a48;
              color: white;
            }
            .name-container {
              .name {
                color: #44566c;
                font-weight: 400;
                font-size: 15px;
                word-break: break-word;
              }
              .mail {
                color: #8697a8;
                font-weight: 400;
                font-size: 13px;
              }
            }
          }
        }

        .color-dot {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          .dot {
            height: 5px;
            width: 5px;
            background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
            border-radius: 50%;
            display: inline-block;
            &.red {
              background-color: #ff3d57;
            }
            &.green {
              background-color: #09b66d;
            }
            &.yellow {
              background-color: #fdbf5e;
            }
          }
        }
      }
    }
    .doc-grid-system {
      .mb {
        margin-bottom: 120px;
      }
      .all-cards {
        display: grid;
        grid-template-columns: auto auto auto;
        @media screen and (min-width: 1921px) {
          grid-template-columns: auto auto auto auto auto;
        }
        gap: 108px 28px;
        flex-wrap: wrap;
        .card {
          height: 370px;
          width: 100%;
          filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
          border-radius: 20px;
          background-color: white;
          position: relative;
          .img {
            position: absolute;
            height: 170px;
            width: 170px;
            margin-left: auto;
            margin-right: auto;
            top: -86px;
            left: calc(50% - 85px);
            z-index: 4;
            img {
              display: block;
              border-radius: 40px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              box-shadow: 0px 2px 4px rgba(169, 194, 209, 0.05), 0px 12px 16px rgba(169, 194, 209, 0.1);
            }
          }
          .more-info {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            &:hover {
              background-color: rgba(255, 255, 255, 0);
            }
          }
          .empty {
            height: 126px;
          }
          .top {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 9px;
            height: 90px;
            background-color: white;
            border-bottom: 1px solid #eaedf0;
            .name {
              font-weight: 500;
              font-size: 30px;
              line-height: 35px;
              color: #44566c;
            }
            .title {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              background-color: #0081ff;
              color: #ffffff;
              padding: 2px 5px;
              border-radius: 5px;
            }
          }
          .name-info {
            height: 90px;
            background-color: white;
            border-bottom: 1px solid #eaedf0;
          }
          .adress-info {
            height: 156px;
            background-color: #f8fafb;
            display: flex;
            flex-direction: column;
            padding: 25px 30px 25px 30px;
            gap: 25px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            .city,
            .email,
            .phone-number {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .text-left {
              display: flex;
              gap: 8px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #44566c;
              align-items: center;
            }
            .text-right {
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #8697a8;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 30px;
      width: 100%;
      height: 40px;
      .info {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #8697a8;
      }

      .el-pagination {
        background-color: #fff;
        .number {
          background-color: #fff;
        }
        .number:active {
          background-color: #409eff;
          color: #fff;
        }
      }
      .btn-next,
      .btn-prev {
        background-color: #fff;
      }
    }
  }
}
</style>
